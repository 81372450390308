import React from "react";

import ConfortInteriorImg from "src/components/products/howDoesItWorkImgBottom/images/climatecoating-thermoplus-temperatura-uniforme.jpg";

import "src/components/products/howDoesItWorkImgBottom/style.scss";

export const InteriorConfortHowDoesItWork = (props) => (
  <div className="howDoesItWorkImgBottom">
    <h3>Control de la Humedad Interior</h3>
    <p>
      Gracias a su capacidad de difusión del vapor de agua, almacena el exceso
      de humedad del aire interior de forma que regula la humedad relativa en
      torno al 55%, el adecuado para generar condiciones de confort.
    </p>
    <img src={ConfortInteriorImg} />
  </div>
);

export default InteriorConfortHowDoesItWork;
