import React from "react";

import { MinusOutlined } from "@ant-design/icons";

import howDoesItWorkCoolFeetHotHead from "src/components/products/howDoesItWorkImgLeft/images/efecto-pies-frios-cabeza-caliente-climatecoating-thermoplus.jpg";

import "src/components/products/howDoesItWorkImgLeft/style.scss";

export const CoolFeetHotHeadHowItWorks = (props) => (
  <>
    <div className="blockColdFeetHotHead">
      <img className="coolFeetHotHeadImg" src={howDoesItWorkCoolFeetHotHead} />
      <span className="blockText">
        <h2>Evitar el efecto Pies Fríos - Cabeza Caliente</h2>
        <p>
          En ThermoPlus la membrana termocerámica se ocupa de lograr una
          distribución homogénea del calor en la estancia. La diferencia de
          temperatura entre las zonas próximas al suelo y las próximas al techo
          puede así suponer menos de 2 °C, en lugar de unos 10 °C, que es lo
          habitual en estancias con sistemas de calefacción por radiadores o
          aire acondicionado. De esta forma, evitamos el efecto “pies
          fríos-cabeza caliente”, tan dañino para el bienestar de las personas.
        </p>
      </span>
    </div>
    <div className="clearBoth" />
  </>
);

export default CoolFeetHotHeadHowItWorks;
