import React from "react";

import { List, Card } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import "src/components/products/listPropertyCards/style.scss";

export const ThermoPlusListPropertyCard = (props) => (
  <List
    {...{
      grid: {
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
      dataSource: [
        {
          title: "Genera un escudo térmico",
          listProps: [
            "Equilibrio térmico en los espacios",
            "Control de la humedad interior",
            "Confort térmico todo el año",
            "Ahorro energético",
            "Eco-friendly",
            "Recomendado para alérgicos y asmáticos",
            "Evita la formación de mohos",
          ],
        },
        {
          title: "Propiedades",
          listProps: [
            "Permeabilidad variable al vapor de agua",
            "Alta resistencia a influencias ambientales agresivas",
            "Libre de disolventes",
            "Regula la Humedad en superficies permeables",
          ],
        },

        {
          title: "Tonos y Colores",
          listProps: [
            "100.000 tonos disponibles",
            "Muy alta resistencia del color",
            "Acabado satinado, brillo suave",
          ],
          subTitle: "Cómo Aplicarlo",
          subListProps: ["Rodillo", "Brocha", "Pulverización"],
        },
        {
          title: "Unidades de Envase",
          listProps: [
            "5 litros/ 12,5 litros",
            "Consumo: 330 ml/m2, calculado para dos capas, sobre superficies lisas de baja porosidad",
          ],
        },
      ],
      renderItem: (item) => (
        <List.Item>
          <Card className="propertyCard">
            <span className="propertyTitle">{item.title}</span>
            <ul className="propertyCardList">
              {item.listProps.map((prop) => (
                <li>
                  <CheckOutlined />
                  {prop}
                </li>
              ))}
            </ul>
            {item.subTitle && (
              <>
                <span className="propertyTitle">{item.subTitle}</span>
                <ul className="propertyCardList">
                  {item?.subListProps.map((prop) => (
                    <li>
                      <CheckOutlined />
                      {prop}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Card>
        </List.Item>
      ),
    }}
  />
);

export default ThermoPlusListPropertyCard;
