import React from "react";

import { Table } from "antd";

import "src/components/products/tableTechnicalData/style.scss";

export const ThermoPlusTableTechnicalData = (props) => {
  const columns = [
    { title: "Propiedad", dataIndex: "property" },
    {
      title: "Valor",
      dataIndex: "value",
    },
    {
      title: "Normativa",
      dataIndex: "norma",
    },
  ];

  const dataSource = [
    ["Densidad", "1,12 kg/dm3", "ISO 2811-1"],
    ["Comportamiento al fuego", "B-s1, d0", "DIN EN 13501-1"],
    [
      "Permeabilidad al vapor de agua",
      "área seca: sd = 1,7 área húmeda: sd = 0,02",
      "DIN 52615",
    ],
    ["COV", "< 3 g/l", "PN_EN ISO 2811-1:2012"],
    ["Total solar reflectance", "88,7%", "ASTM E903"],
    [
      "Resistencia a la abrasión",
      "Clase 3 en condiciones húmedas",
      "DIN-EN 13300",
    ],
    ["Brillo", "0,4", "DIN ISO 2813"],
    ["Contraste relación – potencia de cobertura", "Clase 2", "DIN-EN 13300"],
    ["Impermeabilidad al agua", "Columna de agua 433 mm", "DIN EN 20811"],
    [
      "Resistencia química",
      "Buena resistencia en una amplia variedad de productos químicos (información disponible bajo consulta)",
      "ASTM 1308-02",
    ],
  ].map((data, i) => ({
    key: i,
    property: data[0],
    value: data[1],
    norma: data[2],
  }));

  return (
    <div className="tableTechnicalData">
      <h2>Propiedades</h2>
      <Table
        {...{
          dataSource,
          columns,
          pagination: false,
          bordered: true,
        }}
      />
    </div>
  );
};

export default ThermoPlusTableTechnicalData;
