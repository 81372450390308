import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { Divider } from "antd";

import ThermoPlusImgAndIntro from "src/components/products/imgAndIntro/ThermoPlus";
import CoolFeetHotHeadHowItWorks from "src/components/products/howDoesItWorkImgLeft/ColdFeetHotHead";
import InteriorHumidityHowDoesItWork from "src/components/products/howDoesItWorkImgBottom/InteriorHumidity";
import InteriorConfortHowDoesItWork from "src/components/products/howDoesItWorkImgBottom/InteriorConfort";
import ThermoPlusListPropertyCard from "src/components/products/listPropertyCards/ThermoPlus";

import ThermoPlusTableTechnicalData from "src/components/products/tableTechnicalData/ThermoPlus";

import GreenGuardCertificate from "src/components/products/blocks/GreenGuard";
import EnergySavingInteriorHighligtedBlock from "src/components/products/blocks/highlighted/EnergySavings";

import InteriorProductsMenu from "src/menus/product/Interior";

import "src/components/products/style.scss";
import "src/components/products/thermoPlus/style.scss";

const ThermoPlus = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      productName: "thermoPlus",
      pageTitle: "ClimateCoating ThermoPlus",
      pageName: "ThermoPlus",
      pageDescription:
        "ClimateCoating ThermoPlus: Membrana Termocerámica con redimiento endotérmico para interiores",
    }}
  >
    <InteriorProductsMenu {...{ activePage: "thermoPlus" }} />

    <Content className="product thermoPlus">
      <ThermoPlusImgAndIntro />

      <Divider className="dividerHorizontalCC" />

      <CoolFeetHotHeadHowItWorks />

      <EnergySavingInteriorHighligtedBlock />

      <div className="clearBoth" />

      <div
        style={{
          width: "90%",
          maxWidth: "1000px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "75px",
          marginBottom: "50px",
        }}
      >
        <video width="100%" controls>
          <source
            src="/videos/ClimateCoating-ThermoPlus-presentacion.mp4"
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>

      <InteriorHumidityHowDoesItWork />

      <Divider className="dividerHorizontalCC" />

      <InteriorConfortHowDoesItWork />

      <div className="clearBoth marginTopBottom20" />

      <ThermoPlusListPropertyCard />

      <div className="clearBoth marginTopBottom20" />
      <ThermoPlusTableTechnicalData />
    </Content>
  </LayoutGeneral>
);

export default ThermoPlus;
