import React from "react";

import ThermoPlusImage from "src/components/products/images/thermoPlus.jpg";
import { Link } from "gatsby";
import { Row, Col, List } from "antd";
import {
  FilePptOutlined,
  FileProtectOutlined,
  SolutionOutlined,
  CheckOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import "src/components/products/imgAndIntro/style.scss";

export const ThermoPlusImgAndIntro = (props) => (
  <div className="imgAndIntro">
    <div className="productImageLinksBlock">
      <img src={ThermoPlusImage} />
      <Row className="productLinks">
        <Col span="8">
          <span className="linkIcon">
            <FilePptOutlined />
          </span>
          <span className="linkText">Presentación</span>

          <span id="productPresentation" className="downloadIcon">
            <a
              href="/thermoPlus/climateCoating-thermoPlus-presentacion.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>

        <Col span="8">
          <Link to="/fichas-tecnicas/thermoplus/">
            <span className="linkIcon">
              <FileProtectOutlined />
            </span>
            <span className="linkText">Ficha Técnica</span>
          </Link>

          <span id="productFichaTecnica" className="downloadIcon">
            <a
              href="/thermoPlus/climateCoating-thermoPlus-ficha-tecnica.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>
        <Col span="8">
          <Link to="/fichas-de-aplicacion/thermoplus/">
            <span className="linkIcon">
              <SolutionOutlined />
            </span>
            <span className="linkText">Ficha de Aplicación</span>
          </Link>
          <span id="productFichaAplicacion" className="downloadIcon">
            <a
              href="/thermoPlus/climateCoating-thermoPlus-ficha-de-aplicacion.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>
      </Row>{" "}
    </div>
    <h1>
      <span className="paddingRight10"></span>ThermoPlus
    </h1>
    <p>
      Revestimiento termocerámico para interior que genera un escudo térmico
      tanto en verano como en invierno.
    </p>
    <p>
      Sentirse bien en casa es cuestión del ambiente y{" "}
      <strong>ClimateCoating ThermoPlus</strong> contribuye a ello de manera
      decisiva creando un agradable confort térmico en cualquier clase de
      edificio, equilibrando la temperatura en las distintas zonas del espacio
      interior y contribuyendo a crear un ambiente saludable para las personas.
    </p>

    <ul className="productFeatureList">
      <li>
        {" "}
        <CheckOutlined />
        Genera condiciones de confort térmico
      </li>
      <li>
        <CheckOutlined />
        Ahorra costes en calefacción y aire acondicionado
      </li>
      <li>
        <CheckOutlined />
        Regula la humedad interior en el porcentaje óptimo
      </li>
      <li>
        <CheckOutlined />
        Permeable al vapor de agua, transpirable
      </li>
      <li>
        <CheckOutlined />
        Previene la formación de condensaciones y moho
      </li>
      <li>
        <CheckOutlined />
        Bajas emisiones y libre de disolventes
      </li>
      <li>
        <CheckOutlined />
        Recomendado para personas alérgicas y asmáticas
      </li>
      <li>
        <CheckOutlined />
        Repele la suciedad
      </li>
      <li>
        <CheckOutlined />
        Prolonga los intervalos de renovación de aplicación de
        revestimiento/pintura
      </li>
    </ul>
  </div>
);

export default ThermoPlusImgAndIntro;
